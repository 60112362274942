.qr-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100%;
    background-color: #0003;
}

.qr-reader {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: min(80vw, 30rem);
    height: max-content;
    min-height: min(80vw, 30rem);
    padding: 24px;
    background-color: white;
    border-radius: 12px;
}

.qr-reader video {
    width: 100%;
    height: min(80vw, 30rem);
    object-fit: cover;
    border-radius: 4px;
}

.qr-reader .qr-box {
    width: 100% !important;
    left: 0 !important;
}

.qr-text {
    font-size: 14px;
    text-align: center;
}

.qr-reader .qr-frame {
    position: absolute;
    fill: none;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(0);
}

.qr-result-text {
    max-width: 100%;
    word-break: break-all;
}

p:has(+ button) {
    margin-bottom: 24px;
}

button {
    margin-inline: auto;
}

@media (max-width: 426px) {
    .qr-reader {
        width: min(90vw, 30rem);
    }

    .qr-reader .qr-frame {
        transform: translateX(-50%) translateY(-10%);
    }
}
